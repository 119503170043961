<template>
  <breadcrumb-page :list="breadcrumb_links"></breadcrumb-page>

  <div id="maincontent_container">
    <company-submenu menu="policy"></company-submenu>


      <div class="submenu-content">
          <h2>Ma Laboratories Privacy Notice</h2>
          <p>Last updated as of: January 1, 2020</p>

          <p class="indent">
            <strong
              >PLEASE CLICK <a :href="'/'+item">HERE</a> FOR A
              DOWNLOADABLE PRINTER-FRIENDLY COPY OF THIS PRIVACY NOTICE</strong
            >
          </p>

          <p>
            Thank you for visiting Ma Laboratories, Inc.'s ("Ma Labs", "we",
            "us", "our") Internet web site located at www.malabs.com ("Site").
          </p>

          <p>
            In order to provide the Site and our products ("Products"), we
            collect and process personal information. This Privacy Notice and
            our <router-link :to="{ name: 'CookieNotice'}">Cookie&nbsp;Notice</router-link> govern how Ma Labs
            may collect, use, store and disclose personal information that we
            obtain through or from:
          </p>
          <ul>
            <li>Individuals who visit, access and contact us via the Site;</li>
            <li>
              Individuals who apply to become resellers and purchase Products
              for resale;
            </li>
            <li>
              Other individuals who may provide us with personal information
            </li>
          </ul>
          <p></p>

          <p>
            This Privacy Notice and our
            <router-link :to="{ name: 'CookieNotice'}">Cookie&nbsp;Notice</router-link> cover our Site and any
            other websites, products, software, applications, data feeds,
            content or services on which authorized links to this Privacy Notice
            and <router-link :to="{ name: 'CookieNotice'}">Cookie&nbsp;Notice</router-link> are posted.
          </p>

          <p>
            If you have any questions, comments, or concerns regarding this
            Privacy Notice and/or our data practices, or would like to exercise
            your rights, do not hesitate to contact us at:
            <a href="mailto:privacynotice@malabs.com"
              >privacynotice@malabs.com</a
            >
          </p>

          <p>
            <strong>IF YOU ARE CALIFORNIA RESIDENT</strong>: If you are a
            resident of California, this entire Privacy Notice applies to you.
            However, please see the section titled "<a
              href="#additional_california"
              >Additional Notices for California Consumers</a
            >" below, which will inform you in detail about our information
            collection practices and your specific rights.
          </p>

          <p>
            <strong>IF YOU ARE AN INDIVIDUAL LOCATED IN THE EEA</strong>: If you
            are located in the European Economic Area ("EEA"), this entire
            Privacy Notice applies to you. However, please see the Section
            titled "<a href="#additional_eea"
              >Additional Information for Users in the European Economic Area</a
            >" below, which will inform you in detail about our legal bases for
            processing and your rights regarding the processing of your personal
            data.
          </p>

          <p>
            <strong>IF YOU ARE A RESIDENT OF NEVADA</strong>: If you are a
            resident of Nevada, this entire Privacy Notice applies to you.
            However, please see the Section titled "<a href="#additional_nevada"
              >Notice for Nevada Consumers</a
            >" below, which will also apply to you.
          </p>

          <h2>Who We Are / Data Controller</h2>

          <p>
            If you use our Site or purchase our Products, except as may be
            stated in this Privacy Notice, the
            <strong>data controller</strong> of your information is Ma
            Laboratories, Inc., a California Corporation with the following
            contact information:
          </p>

          <p>
            2075 N. Capitol Avenue, San Jose, CA 95132<br />
            Tel: + 1 (408) 941-0808
          </p>

          <h2 id="what">
            What Personal Information Do We Collect and How Is It Collected?
          </h2>

          <p>
            Depending on who you are and why you are using our Site, we collect
            different types of information.
          </p>

          <p>
            "Personal Information" – or "Personal Data" as also used
            interchangeably throughout this Privacy Notice – means any
            information about an individual from which that person may be
            identified. For example, it may include your name, telephone number,
            email address, payment information, and your IP address, device ID
            and location information. It does not include data from which the
            identity of an individual has been definitively removed along with
            any identifiers connected to such individual.
          </p>

          <p>
            When you visit our Site or purchase our Products, we collect
            Personal Information <a href="#directly">directly</a> from you when
            you provide it to us, as well as
            <a href="#indirectly">indirectly</a> through automated technologies
            such as cookies.
          </p>

          <h3 id="directly">Information Collected Directly</h3>

          <p class="p-section">Account Registration Information</p>

          <p>
            Ma Labs in a business-to-business operation, and you must be an
            approved reseller to purchase and resell our Products. You may sign
            up <strong>online</strong> for a temporary account, which will
            enable you to browse our Product offerings. To do so, we request
            certain Personal Information from you:
          </p>
          <ul>
            <li>your name</li>
            <li>your email</li>
            <li>your password</li>
            <li>your company name and contact information</li>
            <li>your telephone number</li>
          </ul>
          <p></p>

          <p>
            In addition, in order to become a reseller, you must submit separate
            <strong>offline</strong> customer application forms (which also
            require credit reference checks and approvals) before Ma Labs can
            sell Products to you. Ma Labs converts completed customer
            application forms into PDF files and securely stores the files
            digitally on our servers. Customer application forms generally
            require more information and will be accompanied by a separate
            notice.
          </p>

          <p class="p-section">Newsletter Subscription Information</p>

          <p>
            You may sign up for the Ma Labs Email Newsletter, in which case we
            will collect:
          </p>
          <ul>
            <li>your name</li>
            <li>your email</li>
            <li>your company name</li>
            <li>your title</li>
            <li>your Ma Labs Customer ID if you are already registered</li>
            <li>your general location information</li>
          </ul>
          <p></p>

          <p class="p-section">Transaction Information</p>

          <p>
            If you purchase any of our Products, we process information related
            to the Products that you purchase.
          </p>

          <p class="p-section">Email Communications</p>

          <p>
            When you sign up, you may choose to opt in to receiving Ma Labs
            marketing emails. In addition, we may send you email updates from
            time to time. If you communicate with us via email, we will process
            the content of the communication in order to respond to your
            inquiry.
          </p>

          <p>
            If you do not wish to receive certain types of email communications,
            after logging in you may go to your
            <strong>Account Settings</strong> on the Site, and click on
            <strong>Change Email Preferences</strong> to change your options, or
            you may unsubscribe using the link contained in the email.
          </p>

          <h3>Information Collected Indirectly</h3>

          <p id="usage" class="p-section">Device and Usage Information</p>

          <p>
            When you visit, use or interact with the Site, even if you do not
            have an account, we or our authorized third-party service providers
            collect information about your use of the Site via your device.
            Device and Usage Information that we collect consists of:
          </p>
          <ul>
            <li>
              <strong>Information About your Device</strong>: information about
              the devices and software you use to access the Site – primarily
              the internet browser or mobile device that you use, the website or
              source that linked or referred you to the Site, your IP address or
              device ID (or other persistent identifier that uniquely identifies
              your computer or mobile device on the Internet), the operating
              system of your computer or mobile device, device screen size, and
              other similar technical information.
            </li>
            <li>
              <strong>Usage Information</strong>: information about your
              interactions with the Site, including access dates and times,
              hardware and software information, device event information, crash
              data, cookie data. This information allows us to understand the
              screens that you view, how you've used the Site (which may include
              administrative and support communications with us or whether you
              have clicked on third-party links), and other actions on the Site.
              We, or our authorized third parties, automatically collect log
              data when you access and use the Site, even if you have not
              created an account or logged in. We use this information to
              administer and improve the Site, analyze trends, track users' use
              of the Site, and gather broad demographic information for
              aggregate use.
            </li>
          </ul>
          <p></p>

          <p class="p-section">Location Information</p>

          <p>
            We also collect your location information, including city, metro
            code, zip code, state, country, latitude and longitude and area code
            through your IP address.
          </p>

          <p class="p-section">Cookies and Similar Technologies</p>

          <p>
            We or authorized third parties collect some information, including
            Device and Usage Information, by automated means using cookies, web
            beacons, and server logs for analytic purposes. For more information
            on our use of these technologies and the data that they collect, see
            our <router-link :to="{ name: 'CookieNotice'}">Cookie&nbsp;Notice</router-link>
          </p>

          <p class="p-section">Analytics</p>

          <p>
            With the Device and Usage Information collected by our third-party
            analytics service providers, such as Google Analytics, we generate
            and process "Aggregated Information". This includes statistical or
            demographic data. Aggregated Information may be derived from
            personal data, but is not considered personal data under the law if
            it does not directly or indirectly reveal your identity. For
            example, we may track the total number of visitors to our Site or
            the number of visitors to each page of our Site, and we may
            aggregate usage data to calculate the percentage of users accessing
            a specific feature of the Site and analyze this data for trends and
            statistics.
          </p>

          <p>
            However, if we combine or connect Aggregated Information with your
            Personal Data so that it can directly or indirectly identify you, we
            treat the combined data as Personal Data, which will be processed in
            accordance with this Privacy Notice. For more information on
            analytics, see our <router-link :to="{ name: 'CookieNotice'}">Cookie&nbsp;Notice</router-link>.
          </p>

          <p class="p-section">Information from Third Parties</p>

          <p>
            In some instances, we process Personal Information from third
            parties, which primarily consists of:
          </p>
          <ul>
            <li>
              Data from our partners, such as Transaction Data from providers of
              payment service providers; and
            </li>
            <li>Data from companies to which you resell our Products.</li>
          </ul>
          <p></p>

          <h2 id="why">
            Why We Collect Your Personal Information and How We Use It
          </h2>

          <p>
            Our mission is to provide safe, efficient and high-quality Site, and
            we, or our authorized third-party service providers who assist us in
            providing the Site, process your Personal Information for this
            purpose. Personal Information is generally processed in order to:
          </p>
          <ul>
            <li>
              Provide you the Site and enable you to purchase and receive our
              Products;
            </li>
            <li>
              Ensure that our Site are operational and optimized for user
              experience, as well as to enforce our terms of use with you if
              necessary;
            </li>
            <li>
              Improve the content and general administration of the Site, as
              well as Products that we offer;
            </li>
            <li>
              Enhance user experience, including to provide you with customer
              support;
            </li>
            <li>Detect fraud, illegal activities or security breaches;</li>
            <li>Identify and follow up on business leads;</li>
            <li>
              Provide you with notices regarding Products that you have
              purchased or may wish to purchase in the future, such as through
              the Ma Labs newsletter or in some cases via direct marketing
              communications;
            </li>
            <li>
              Respond to your queries and requests, or otherwise communicate
              directly with you;
            </li>
            <li>
              Perform system maintenance and upgrades, and enable new features;
            </li>
            <li>Conduct statistical analyses;</li>
            <li>
              Provide information to regulatory bodies when legally required,
              and only as outlined in this Privacy Notice; and
            </li>
            <li>
              In the event of a business transfer (as further explained below).
            </li>
          </ul>
          <p></p>

          <p>
            <strong>For California residents</strong>, please visit this
            <a href="#additional_california">Section</a> for more information on
            specific purpose of collection for each category of Personal
            Information collected in the past twelve (12) months.
          </p>

          <p>
            <strong>For individuals located in the EEA</strong>, please visit
            this <a href="#additional_eea">Section</a> for more information on
            our legal bases for processing.
          </p>

          <h2>Children's Privacy</h2>

          <p>
            Our Site and/or Products are not intended for children under the age
            of 18 and Ma Labs does not target the Site or its Products to
            children under 18. Ma Labs does not knowingly collect personal
            information from children under the age of 18.
          </p>

          <h2>Managing Your Preferences</h2>

          <p class="p-section">Accessing and Updating Your Personal Data</p>

          <p>
            If Personal Data about you changes, or if you no longer desire to
            use our Site, you may correct, update, amend, or deactivate your
            account under your account settings or by contacting Ma Labs using
            the information set out in this Privacy Notice. We will respond to
            your request within a reasonable timeframe.
          </p>

          <p class="p-section">Direct Marketing</p>

          <p>
            Ma Labs enables you to manage your marketing preferences by clicking
            on the unsubscribe link contained in each electronic communication
            to you. Please use your preference settings to inform us of how you
            would like to receive marketing communications. Updates to your
            privacy preference information will be submitted once you have
            confirmed your changes.
          </p>

          <p>
            You can also opt out of further direct marketing emails by clicking
            on the link at the end of each email message that you receive from
            us.
          </p>

          <h2 id="disclosure">Disclosure of Your Personal Information</h2>

          <p>
            We disclose your Personal Information to the third parties indicated
            below:
          </p>
          <ul>
            <li>
              Companies that do things to help us provide the Site: hosting
              service providers, Product delivery services, user engagement and
              customer support providers, payment service providers,
              communication tools, and analytics tools;
            </li>
            <li>
              Professional service providers, such as auditors, lawyers,
              consultants, accountants and insurers;
            </li>
            <li>
              Governments, regulators, law enforcement and fraud prevention
              agencies, but only as authorized as explained
              <a href="#legal_obligations">here</a>;
            </li>
            <li>
              Third party organizations, in the event of a business transfer,
              sale, merger or bankruptcy; and
            </li>
            <li>
              Third parties that may provide marketing to you that we believe
              may be of interest to you
            </li>
          </ul>
          <p></p>

          <h3>Third-Party Service Providers</h3>

          <p>
            Ma Labs may share users' information with our third party agents,
            contractors, or service providers who are hired to perform services
            on Ma Labs' behalf. These providers may operate or support certain
            functions of the Site. Below is a list of categories of service
            providers that we may use to perform these functions (subject to
            change):
          </p>
          <ul>
            <li>Analytics services</li>
            <li>Customer support services</li>
            <li>Billing services and payment gateway providers</li>
            <li>Hosting and content delivery network services</li>
            <li>Product delivery partners.</li>
          </ul>
          <p></p>

          <h3>Business Transfers</h3>

          <p>
            As we continue to grow, we or our affiliates may purchase websites,
            applications, subsidiaries, other businesses or business units.
            Alternatively, we may sell businesses or business units, merge with
            other entities and/or sell assets or stock, in some cases as part of
            a reorganization or liquidation in bankruptcy. As part of these
            transactions, we or our affiliates may transfer your Personal
            Information to a successor entity upon a merger, consolidation or
            other corporate reorganization in which Ma Labs participates, or to
            a purchaser or acquirer of all or a portion of Ma Labs' assets,
            bankruptcy included.
          </p>

          <h3 id="legal_obligation">Legal Obligations and Security</h3>

          <p>
            Ma Labs may preserve or disclose your Personal Information in
            limited circumstances (other than as set forth in this Privacy
            Notice), including: (i) with your consent; (ii) when we have a good
            faith belief it is required by law, such as pursuant to a subpoena,
            warrant or other judicial or administrative order (as further
            explained below); (iii) to protect the safety of any person; to
            protect the safety or security of our Site or to prevent spam,
            abuse, or other malicious activity of actors with respect to the
            Site; or (iv) to protect our rights or property or the rights or
            property of those who use the Site.
          </p>

          <p>
            If we are required to disclose Personal Information by law, such as
            pursuant to a subpoena, warrant or other judicial or administrative
            order, our policy is to respond to requests that are properly issued
            by law enforcement within the United States or via mutual legal
            assistance mechanism (such as a treaty).
          </p>

          <p>
            Note that if we receive information that provides us with a good
            faith belief that there is an exigent emergency involving the danger
            of death or serious physical injury to a person, we may provide
            information to law enforcement trying to prevent or mitigate the
            danger (if we have it), to be determined on a case-by-case basis.
          </p>

          <h2>Payment Processing</h2>

          <p>
            We do not directly collect or hold any payment information and we do
            not store any payment information. We use third-party,
            PCI-compliant, payment processors, which collect payment information
            on our behalf in order to complete transactions. While we receive
            payment confirmations when a user makes a purchase, we do not have
            access to or process your credit card information. In some cases,
            payments may be made by check.
          </p>

          <h2>"Do Not Track"</h2>

          <p>
            Ma Labs will respond to Do Not Track ("DNT") signals. For more
            information on DNT settings generally, please visit
            <a href="https://allaboutdnt.com">https://allaboutdnt.com</a>.
          </p>

          <h2>Protecting Your Personal Information</h2>

          <p>
            No method of transmission over the Internet, or method of electronic
            storage, is 100% secure, however we take steps that are reasonably
            necessary to securely provide our Site. We have put in place
            reasonably appropriate security measures designed to prevent your
            Personal Data from being accidentally lost, used or accessed in an
            unauthorized way, altered or disclosed. We limit access to Personal
            Data only to those employees, agents, contractors and the third
            parties who have a business need-to-know.
          </p>

          <p>
            We also have procedures in place to deal with any suspected data
            security breach. If required, we will notify you and any applicable
            regulator of a suspected data security breach. We also require those
            parties to whom we transfer your Personal Information to provide
            acceptable standards of security.
          </p>

          <h2>How Long Do We Keep Your Personal Information?</h2>

          <p class="p-section">General Retention Periods</p>

          <p>
            We use the following criteria to determine our retention periods:
            the amount, nature and sensitivity of your information, the reasons
            for which we collect and process your Personal Data, the length of
            time we have an ongoing relationship with you and provide you with
            access to our Site, and applicable legal requirements. We will
            retain Personal Information we collect from you where we have an
            ongoing legitimate business need to do so (for example, to comply
            with applicable legal, tax or accounting requirements), when we are
            unable to reasonably verify your identity, or as may otherwise be
            required under GDPR. Additionally, we cannot delete information when
            it is needed for the establishment, exercise or defense of legal
            claims (also known as a "litigation hold"). In this case, the
            information must be retained as long as needed for exercising
            respective potential legal claims.
          </p>

          <p>
            When we have no ongoing business need to process your Personal
            Information, we will either delete or anonymize it or, if this is
            not possible (for example, because your Personal Information has
            been stored in backup archives), we will securely store your
            Personal Information and isolate it from any further processing
            until deletion is possible.
          </p>

          <p>
            If you have questions about, or need further information concerning,
            our data retention periods, please send an email to
            <a href="mailto:privacynotice@malabs.com"
              >privacynotice@malabs.com</a
            >.
          </p>

          <p class="p-section">Time Frame of Deletion</p>

          <p>
            If Personal Data can no longer be retained or is no longer
            necessary, it will be erased or anonymized/de-identified without
            undue delay.
          </p>

          <p class="p-section">Anonymization</p>

          <p>
            In some instances, we may choose to anonymize your Personal Data
            instead of deleting it, for statistical use, for instance. When we
            choose to anonymize, we make sure that there is no way that the
            Personal Data can be linked back to you or any specific user.
          </p>

          <h2>International Transfers</h2>

          <p>
            We are located in the United States, and the Personal Information
            that we collect is stored on servers located in the United States.
            This means that your Personal Information will be collected,
            processed and stored in the United States, which may have data
            protection laws that are different from (and sometimes less
            protective than) the laws of your country or region, such as the
            GDPR.
          </p>

          <p>
            <strong
              >By sending us Personal Information, you agree and consent to the
              processing of your Personal Information in the United States,
              which may not offer an equivalent level of protection to that
              required in other countries (particularly the European Union), and
              to the processing of that information by us on servers located in
              the United States, as described in this Privacy Notice.</strong
            >
          </p>

          <p>
            We have implemented safeguards designed to ensure that the Personal
            Information we process remains protected in accordance with this
            Privacy Notice, including when processed internationally or by our
            third-party service providers and partners. The safeguards we may
            take in our discretion include, for instance, entering into binding
            agreements in connection with any onward transfers of Personal
            Information. We may implement other mechanisms and take similar
            appropriate safeguards with our third-party service providers and
            partners. Further details can be provided upon request.
          </p>

          <h2>Changes to this Privacy Notice</h2>

          <p>
            Ma Labs may update this Privacy Notice from time to time, at its
            sole discretion. If so, we will post an updated Privacy Notice
            within the Site along with a change notice. Changes, modifications,
            additions, or deletions will be effective immediately on their
            posting to the Site. If we make significant changes, we may also
            send registered users a notice that this Privacy Notice has been
            changed. We encourage you to review this Privacy Notice regularly
            for any changes. Your continued use of the Site and/or your
            continued provision of Personal Information to us after the posting
            of such notice will be subject to the terms of the then-current
            Privacy Notice. If you continue to use the Site you will be deemed
            to have accepted the change.
          </p>

          <h2 id="contact">How To Contact Us About Privacy</h2>

          <p>
            If you have any questions regarding this Privacy Notice please
            contact Ma Laboratories, Inc. at:
          </p>

          <p>2075 N. Capitol Avenue, San Jose, CA 95132</p>

          <p>
            Email:
            <a href="mailto:privacynotice@malabs.com"
              >privacynotice@malabs.com</a
            >
          </p>

          <h2 id="additional_nevada">Notice for Nevada Consumers</h2>

          <p>
            We do not sell your personal information within the scope of, and
            according to the defined meaning of a "sale" under, NRS 603A.
          </p>

          <h2 id="additional_eea">
            Additional Information for Individuals in the European Economic Area
          </h2>

          <h3>Categories of Recipients of Personal Data</h3>

          <p>
            The categories of recipients of Personal Data with whom we may share
            your Personal Data are listed in
            <a href="#disclosure">Disclosure of Your Personal Information</a>
            above.
          </p>

          <h3>Purpose of the Processing and Legal Bases</h3>

          <p>
            Ma Labs uses your Personal Information for a number of different
            purposes, as explained <strong><a href="#why">here</a></strong
            >. Some are essential for us to provide the Site you use or to
            fulfill our legal obligations, some help us run the Site efficiently
            and effectively and some enable us to provide you with more relevant
            and personalized offers and information. In all cases we must have a
            reason and a legal ground for processing your Personal Information.
            Some of the most common legal grounds we rely on are briefly
            explained below.
          </p>

          <p class="p-section">Performance of a Contract</p>

          <p>
            We may process your Personal Data for the purposes of a contract to
            which you are a party, in other words your ability to use the Site.
            For instance, if you purchase Products on the Site, we will process
            your data in order to carry out the purchase.
          </p>

          <p class="p-section">Legitimate Interests</p>

          <p>
            We may process Personal Data where it is necessary for our
            legitimate business interests but only to the extent that they are
            not outweighed by your own interests or fundamental rights and
            freedoms. We generally rely on legitimate interests to provide and
            maintain Site that work well and securely, deliver marketing and
            advertising to you, carry out fraud prevention, and generally
            improve the Site. When we rely on this legal basis, if required,
            we'll carry out a legitimate interest assessment to ensure we
            consider and balance any potential impact on you (both positive and
            negative), and your rights under data protection laws.
          </p>

          <p class="p-section">Consent</p>

          <p>
            Ma Labs may rely on consent where it is required, such as with
            respect to Device and Usage Information collected via cookies and
            similar technologies (other than strictly functional cookies), or
            when we're asking you to confirm your marketing preferences. You
            have the right to withdraw your consent at any time if you no longer
            want Ma Labs to processing your Personal Data in connection with the
            activity for which your consent was sought.
          </p>

          <p class="p-section">Legal Obligation</p>

          <p>
            Ma Labs will on occasion be under a legal obligation to obtain and
            disclose your Personal Data. Where possible, we will notify you when
            processing your data due to a legal obligation, however this may not
            always be possible. For instance, we may need to provide your data
            in order to prevent criminal activity or help to detect criminal
            activity, in which case we may share information with law
            enforcement.
          </p>

          <p>
            The following table illustrates in more detail how the above legal
            bases for processing may apply to our primary purposes for
            processing different types of Personal Data:
          </p>

          <table>
            <tbody>
              <tr>
                <td>General Purpose of Processing</td>
                <td>Legal Basis</td>
                <td>Type of Personal Data</td>
              </tr>
              <tr>
                <td>
                  Provide you access to and use of the Site, including
                  registering you for the Site and enabling the purchase of
                  Products
                </td>
                <td>
                  Performance of a Contract<br />
                  Legitimate Interests
                </td>
                <td>
                  Account Registration Information<br />
                  Newsletter Subscription Information<br />
                  Device &amp; Usage Information<br />
                  Transaction Information<br />
                  Email Communications<br />
                  Location Information
                </td>
              </tr>
              <tr>
                <td>
                  Ensure that our Site are operational and optimized for user
                  experience, as well as to enforce our terms of use with you if
                  necessary
                </td>
                <td>
                  Legitimate Interests<br />
                  Performance of a Contract
                </td>
                <td>
                  Account Registration Information<br />
                  Device &amp; Usage Information<br />
                  Email Communications<br />
                  Location Information
                </td>
              </tr>
              <tr>
                <td>
                  Improve the content and general administration of the Site
                </td>
                <td>Legitimate Interests</td>
                <td>
                  Account Registration Information<br />
                  Device &amp; Usage Information
                </td>
              </tr>
              <tr>
                <td>
                  Enhance user experience, including to provide you with
                  customer support
                </td>
                <td>
                  Legitimate Interests<br />
                  Performance of a Contract
                </td>
                <td>
                  Account Registration Information<br />
                  Device &amp; Usage Information<br />
                  Newsletter Subscription Information<br />
                  Email Communications<br />
                  Transaction Information
                </td>
              </tr>
              <tr>
                <td>To identify and follow up on business leads</td>
                <td>Legitimate Interests</td>
                <td>
                  Account Registration Information<br />
                  Newsletter Subscription Information<br />
                  Device &amp; Usage Information<br />
                  Email Communications
                </td>
              </tr>
              <tr>
                <td>Detect fraud, illegal activities or security breaches</td>
                <td>
                  Legitimate Interests<br />
                  Performance of a Contract
                </td>
                <td>
                  Account Registration Information<br />
                  Device &amp; Usage Information
                </td>
              </tr>
              <tr>
                <td>
                  Provide you with notices regarding Products that you have
                  purchased or may wish to purchase in the future, such as
                  through the Ma Labs newsletter or in some cases via direct
                  marketing communications
                </td>
                <td>
                  Performance of a Contract<br />
                  Legitimate Interests<br />
                  Consent
                </td>
                <td>
                  Account Registration Information<br />
                  Device &amp; Usage Information<br />
                  Newsletter Subscription Information<br />
                  Email Communications
                </td>
              </tr>
              <tr>
                <td>
                  Respond to your queries and requests, or otherwise communicate
                  directly with you
                </td>
                <td>
                  Performance of a Contract<br />
                  Legitimate Interests
                </td>
                <td>
                  Account Registration Information<br />
                  Email Communications
                </td>
              </tr>
              <tr>
                <td>
                  Perform system maintenance and upgrades, and enable new
                  features
                </td>
                <td>Legitimate Interests</td>
                <td>
                  Account Registration Information<br />
                  Newsletter Subscription Information<br />
                  Device &amp; Usage Information
                </td>
              </tr>
              <tr>
                <td>Conduct statistical analyses and perform analytics</td>
                <td>Legitimate Interests</td>
                <td>
                  Account Registration Information<br />
                  Program Registration Information<br />
                  Device &amp; Usage Information
                </td>
              </tr>
              <tr>
                <td>
                  Personalize and improve your experience with the Site and
                  other Products provided by Ma Labs, for example by providing
                  customized, personalized, or localized content,
                  recommendations and features based upon inferences drawn from
                  your activity with or within the Site
                </td>
                <td>Legitimate Interests</td>
                <td>
                  Account Registration Information<br />
                  Newsletter Subscription Information<br />
                  Device &amp; Usage Information<br />
                  Transaction Information
                </td>
              </tr>
              <tr>
                <td>
                  Understand how you access and use the Site in order to provide
                  technical functionality, develop new products and Site, and
                  analyze your use of the Site
                </td>
                <td>Legitimate Interests</td>
                <td>
                  Account Registration Information<br />
                  Newsletter Subscription Information<br />
                  Device &amp; Usage Information<br />
                  Transaction Information
                </td>
              </tr>
              <tr>
                <td>
                  Provide information to regulatory bodies when legally
                  required, and only as outlined in this Privacy Notice
                </td>
                <td>
                  Compliance with legal obligation or Legitimate Interests
                </td>
                <td>Only such information as may be required</td>
              </tr>
              <tr>
                <td>
                  In the event of a business transfer (as explained above)
                </td>
                <td>Legitimate Interests</td>
                <td>
                  Only such information as may be necessary to effect the
                  business transfer
                </td>
              </tr>
            </tbody>
          </table>

          <h3>Your Rights and Choices under GDPR</h3>

          <p>
            <strong
              >If the GDPR applies to you because you are in the EEA</strong
            >, you have certain rights in relation to your Personal Data:
          </p>
          <ul>
            <li>
              The right to be informed – our obligation to inform you that we
              process your Personal Data (and that's what we're doing in this
              Privacy Notice);
            </li>
            <li>
              The right of access – your right to request a copy of the Personal
              Data we hold about you (also known as a ‘data subject access
              request');
            </li>
            <li>
              The right to rectification – your right to request that we correct
              Personal Data about you if it is incomplete or inaccurate (though
              we generally recommend first making any changes in your Account
              Settings);
            </li>
            <li>
              The right to erasure (also known as the ‘right to be forgotten') –
              under certain circumstances, you may ask us to delete the Personal
              Data we have about you (unless there's an overriding legal reason
              we need to keep it);
            </li>
            <li>
              The right to restrict processing – your right, under certain
              circumstances, to ask us to suspend our processing of your
              Personal Data;
            </li>
            <li>
              The right to data portability – your right to ask us for a copy of
              your Personal Data in a common format (for example, a .csv file);
            </li>
            <li>
              The right to object – your right to object to us processing your
              Personal Data (for example, if you object to us processing your
              data for direct marketing); and
            </li>
            <li>
              Rights in relation to automated decision-making and profiling –
              our obligation to be transparent about any profiling we do, or any
              automated decision-making.
            </li>
          </ul>
          <p></p>

          <p>
            These rights are subject to certain rules around when you can
            exercise them. If are located in the EEA and wish to exercise any of
            the rights set out above, please contact us (see
            <a href="#contact">How to Contact Us About Privacy</a>).
          </p>

          <p>
            You will not have to pay a fee to access your Personal Data (or to
            exercise any of the other rights) unless your request is clearly
            unfounded, repetitive or excessive. Alternatively, we may refuse to
            comply with your request under those circumstances.
          </p>

          <p>
            We may need to request specific information from you to help us
            confirm your identity. This is a security measure to ensure that
            Personal Data is not disclosed to any person who has no right to
            receive it.
            <u
              >Note that if we are unable to reasonably confirm your identity,
              we will not be able to honor certain requests</u
            >.
          </p>

          <p>
            We will respond to all legitimate requests within one month.
            Occasionally it may take us longer than a month if your request is
            particularly complex or you have made a number of requests. In this
            case, we will notify you and keep you updated as required by law.
          </p>

          <p>
            In addition, if you no longer wish to receive our
            marketing/promotional information, we remind you that you may
            withdraw your consent to direct marketing at any time directly from
            the unsubscribe link included in each electronic marketing message
            we send to you. If you do so, we will promptly update our databases,
            and will take all reasonable steps to meet your request at the
            earliest possible opportunity, but we may continue to contact you to
            the extent necessary for the purposes of providing our Site.
          </p>

          <p>
            Finally, you have the right to make a complaint at any time to the
            supervisory authority for data protection issues in your country of
            residence. We would, however, appreciate the chance to address your
            concerns before you approach the supervisory authority, so please
            contact us directly first.
          </p>

          <h2 id="additional_california">
            Additional Information for California Residents
          </h2>

          <p>
            This Section ("CCPA Notice") supplements the information contained
            in this Privacy Notice and applies solely to all visitors, users,
            and others who reside in the State of California ("Consumers"). We
            have created this CCPA Notice in order to comply with the California
            Consumer Privacy Act of 2018 ("CCPA").
          </p>

          <h3>Categories of Information We Collect</h3>

          <p>
            Our Site collects Personal Information as that term is defined in
            CCPA: information that identifies, relates to, describes,
            references, is capable of being associated with, or could reasonably
            be linked, directly or indirectly, with a particular Consumer or
            device. In particular, within the last twelve (12) months, Ma Labs
            has collected the following <u>categories</u> of Personal
            Information from Consumers:
          </p>

          <table>
            <tbody>
              <tr>
                <td>Category</td>
                <td>Examples</td>
                <td>Collected</td>
              </tr>
              <tr>
                <td><strong>A.</strong> Identifiers.</td>
                <td>
                  A real name, unique personal identifier, online identifier,
                  Internet Protocol address, email address, account name
                </td>
                <td>YES</td>
              </tr>
              <tr>
                <td>
                  <strong>B.</strong> Personal Information categories listed in
                  the California Customer Records Statute (Cal. Civ. Code §
                  1798.80(e)).
                </td>
                <td>
                  A name, signature, address, telephone number, employment.
                </td>
                <td>YES</td>
              </tr>
              <tr>
                <td>
                  <strong>C.</strong> Protected classification characteristics
                  under California or federal law.
                </td>
                <td>
                  Age (40 years or older), race, color, ancestry, national
                  origin, citizenship, religion or creed, marital status,
                  medical condition, physical or mental disability, sex
                  (including gender, gender identity, gender expression,
                  pregnancy or childbirth and related medical conditions),
                  sexual orientation, veteran or military status, genetic
                  information (including familial genetic information).
                </td>
                <td>NO</td>
              </tr>
              <tr>
                <td><strong>D.</strong> Commercial information.</td>
                <td>
                  Records of products purchased, obtained, or considered, or
                  other purchasing or consuming histories or tendencies.
                </td>
                <td>YES</td>
              </tr>
              <tr>
                <td><strong>E.</strong> Biometric information.</td>
                <td>
                  Genetic, physiological, behavioral, and biological
                  characteristics, or activity patterns used to extract a
                  template or other identifier or identifying information, such
                  as, fingerprints, faceprints, and voiceprints, iris or retina
                  scans, keystroke, gait, or other physical patterns, and sleep,
                  health, or exercise data.
                </td>
                <td>NO</td>
              </tr>
              <tr>
                <td>
                  <strong>F.</strong> Internet or other similar network
                  activity.
                </td>
                <td>
                  Browsing history, information on a Consumer's interaction with
                  a website or application.
                </td>
                <td>YES</td>
              </tr>
              <tr>
                <td><strong>G.</strong> Geolocation data.</td>
                <td>Physical location or movements.</td>
                <td>YES</td>
              </tr>
              <tr>
                <td><strong>H.</strong> Sensory data.</td>
                <td>Audio, visual information.</td>
                <td>NO</td>
              </tr>
              <tr>
                <td>
                  <strong>I.</strong> Non-public education information (per the
                  Family Educational Rights and Privacy Act
                </td>
                <td>
                  Education records directly related to a student maintained by
                  an educational institution or party acting on its behalf, such
                  as grades, transcripts, class lists, student schedules,
                  student identification codes, student financial information,
                  or student disciplinary records.
                </td>
                <td>NO</td>
              </tr>
              <tr>
                <td>
                  <strong>J.</strong> Inferences drawn from other Personal
                  Information.
                </td>
                <td>
                  Profile reflecting a person's preferences, characteristics,
                  psychological trends, predispositions, behavior, attitudes,
                  intelligence, abilities, and aptitudes.
                </td>
                <td>YES</td>
              </tr>
            </tbody>
          </table>

          <p>Personal Information does <u>not</u> include:</p>
          <ul>
            <li>Publicly available information from government records.</li>
            <li>Deidentified or aggregated Consumer information.</li>
            <li>
              Certain other information that is already regulated by other laws
              or regulations.
            </li>
          </ul>
          <p></p>

          <p>
            As explained in more detail <a href="#what">here</a>, Ma Labs
            obtains the categories of Personal Information listed above from the
            following categories of sources:
          </p>
          <ul>
            <li>
              <em>Directly from you</em>. For example, when you sign up to use
              the Site. This includes the following categories:<br />
              A. Identifiers.<br />
              B. Personal Information categories listed in the California
              Customer Records Statute (Cal. Civ. Code § 1798.80(e)).<br />
              D. Commercial information.<br />
              G. Geolocation data.
            </li>
            <li>
              <em>Indirectly</em>. For example, through the use of automated
              technologies or from third parties. This includes the following
              categories:<br />
              A. Identifiers.<br />
              F. Internet or other similar network activity.<br />
              G. Geolocation data.<br />
              J. Inferences drawn from other Personal Information.
            </li>
            <li>
              <em>By observing Consumers' behavior</em> through their purchases
              and activity on the Site. This includes the following
              categories:<br />
              A. Identifiers.<br />
              B. Personal Information categories listed in the California
              Customer Records Statute (Cal. Civ. Code § 1798.80(e)).<br />
              D. Commercial information.<br />
              F. Internet or other similar network activity.<br />
              G. Geolocation data<br />
              J. Inferences drawn from other Personal Information.
            </li>
          </ul>
          <p></p>

          <h3>Use of Personal Information</h3>

          <p>
            We may use, or disclose the Personal Information we collect for one
            or more of the following business purposes:
          </p>

          <table>
            <tbody>
              <tr>
                <td>Categories of Information</td>
                <td>Business Purpose</td>
              </tr>
              <tr>
                <td>
                  A. Identifier<br />
                  B. Personal Information categories listed in the California
                  Customer Records statute (Cal. Civ. Code § 1798.80(e))<br />
                  D. Commercial information<br />
                  F. Internet or other similar network activity<br />
                  G. Geolocation data<br />
                  J. Inferences drawn from other Personal Information<br />
                </td>
                <td>
                  Provide you the Site and enable you to purchase and receive
                  our Products
                </td>
              </tr>
              <tr>
                <td>
                  A. Identifiers<br />
                  B. Personal Information categories listed in the California
                  Customer Records statute (Cal. Civ. Code § 1798.80(e))<br />
                  D. Commercial information<br />
                  F. Internet or other similar network activity<br />
                  G. Geolocation data<br />
                  J. Inferences drawn from other Personal Information<br />
                </td>
                <td>To identify and follow up on business leads</td>
              </tr>
              <tr>
                <td>
                  A. Identifiers<br />
                  D. Commercial information<br />
                  F. Internet or other similar network activity<br />
                  G. Geolocation data<br />
                </td>
                <td>
                  Ensure that our Site is operational and optimized for user
                  experience, as well as to enforce our terms of use with you if
                  necessary
                </td>
              </tr>
              <tr>
                <td>
                  F. Internet or other similar network activity<br />
                  G. Geolocation data<br />
                  J. Inferences drawn from other Personal Information
                </td>
                <td>
                  Improve the content and general administration of the Site
                </td>
              </tr>
              <tr>
                <td>
                  A. Identifiers<br />
                  D. Commercial information<br />
                  F. Internet or other similar network activity
                </td>
                <td>
                  Enhance user experience, including to provide you with
                  customer support
                </td>
              </tr>
              <tr>
                <td>
                  A. Identifiers<br />
                  D. Commercial information<br />
                  F. Internet or other similar network activity<br />
                  G. Geolocation data
                </td>
                <td>Detect fraud, illegal activities or security breaches</td>
              </tr>
              <tr>
                <td>
                  A. Identifiers<br />
                  D. Commercial information<br />
                  F. Internet or other similar network activity<br />
                  J. Inferences drawn from other Personal Information
                </td>
                <td>
                  Provide you with notices regarding Products that you have
                  purchased or may wish to purchase in the future, such as
                  through the Ma Labs newsletter or in some cases via direct
                  marketing communications
                </td>
              </tr>
              <tr>
                <td>
                  A. Identifiers B. Personal Information categories listed in
                  the California Customer Records statute (Cal. Civ. Code §
                  1798.80(e)) D. Commercial information
                </td>
                <td>
                  Respond to your queries and requests, or otherwise communicate
                  directly with you
                </td>
              </tr>
              <tr>
                <td>
                  F. Internet or other similar network activity G. Geolocation
                  data
                </td>
                <td>
                  Perform system maintenance and upgrades, and enable new
                  features
                </td>
              </tr>
              <tr>
                <td>
                  A. Identifiers D. Commercial information F. Internet or other
                  similar network activity G. Geolocation data J. Inferences
                  drawn from other Personal Information
                </td>
                <td>Conduct statistical analyses</td>
              </tr>
              <tr>
                <td>
                  A. Identifiers
                </td>
                <td>
                  Personalize and improve your experience with the Site and
                  other
                </td>
              </tr>
              <tr>
                <td>
                  D. Commercial information F. Internet or other similar network
                  activity G. Geolocation data J. Inferences drawn from other
                  Personal Information
                </td>
                <td>
                  Site and products provided by Ma Labs, for example by
                  providing customized, personalized, or localized content,
                  recommendations and features based upon inferences drawn from
                  your activity with or within the Site
                </td>
              </tr>
              <tr>
                <td>
                  A. Identifiers D. Commercial information F. Internet or other
                  similar network activity J. Inferences drawn from other
                  Personal Information
                </td>
                <td>
                  Understand how you access and use the Site in order to provide
                  technical functionality, develop new products and Site, and
                  analyze your use of the Site, such as your interaction with
                  applications, advertising, products, and Site that are made
                  available, linked to, or offered through the Site
                </td>
              </tr>
              <tr>
                <td>
                  Any category of Personal Information as may be required and
                  only as outlined in this Privacy Notice
                </td>
                <td>
                  Provide information to regulatory bodies when legally
                  required, and only as outlined in this Privacy Notice
                </td>
              </tr>
              <tr>
                <td>
                  Only such information as may be necessary to effect the
                  business transfer
                </td>
                <td>
                  In the event of a business transfer (as explained above)
                </td>
              </tr>
            </tbody>
          </table>

          <p>
            Ma Labs will not collect additional categories of Personal
            Information or use the Personal Information we collected for
            materially different, unrelated, or incompatible purposes without
            providing you notice.
          </p>

          <h3>Disclosure of Personal Information</h3>

          <p>
            Ma Labs may disclose your Personal Information to a third party for
            a business purpose. When we disclose Personal Information for a
            business purpose, we enter into a contract that describes the
            purpose and requires the recipient to both keep that Personal
            Information confidential and not use it for any purpose except
            performing the services for us.
          </p>

          <p>
            As explained in more detail <a href="#disclosure">above</a>, we
            share your Personal Information with certain categories of third
            parties who assist us in providing our Site and Products.
          </p>

          <p class="p-section">
            Disclosures of Personal Information for a Business Purpose
          </p>

          <p>
            In the preceding twelve (12) months, Ma Labs has disclosed the
            following categories of Personal Information for a business purpose:
          </p>
          <p class="indented">
            A. Identifiers.<br />
            B. Personal Information categories listed in the California Customer
            Records Statute (Cal. Civ. Code § 1798.80(e)).<br />
            D. Commercial information.<br />
            F. Internet or other similar network activity.<br />
            G. Geolocation data<br />
            J. Inferences drawn from other Personal Information.
          </p>
          <p></p>

          <p>
            We disclose your Personal Information for a business purpose to the
            following categories of third parties:
          </p>
          <ul>
            <li>
              Companies that do things to help us provide the Site and our
              Products: hosting service providers, customer support providers,
              analytics tools, delivery partners; and
            </li>
            <li>
              Professional service providers, such as auditors, lawyers,
              consultants, accountants and insurers.
            </li>
          </ul>
          <p></p>

          <p class="p-section">Sales of Personal Information</p>

          <p>Ma Labs does not sell your Personal Information.</p>

          <h3>Your Rights and Choices under CCPA</h3>

          <p>
            The CCPA provides Consumers with specific rights regarding their
            Personal Information, provided that we are able to verify their
            identities as explained <a href="#exercising">here</a>. This section
            describes your CCPA rights and explains how to exercise those
            rights.
          </p>

          <p class="p-section">
            Access to Specific Information and Data Portability Rights
          </p>

          <p>
            You have the right to request that Ma Labs disclose certain
            information to you about our collection and use of your Personal
            Information over the past 12 months. Once we receive your request
            <strong>and verify your identity</strong> (see
            <a href="#exercising">Exercising Your Rights</a>), we will disclose
            to you:
          </p>
          <ul>
            <li>
              The categories of Personal Information we collected about you.
            </li>
            <li>
              The categories of sources for the Personal Information we
              collected about you.
            </li>
            <li>
              Our business or commercial purpose for collecting or selling that
              Personal Information.
            </li>
            <li>
              The categories of third parties with whom we share that Personal
              Information.
            </li>
            <li>
              The specific pieces of Personal Information we collected about you
              (also called a data portability request).
            </li>
            <li>
              If we sold or disclosed your Personal Information for a business
              purpose, two separate lists disclosing:
              <ul>
                <li>
                  sales, identifying the Personal Information categories that
                  each category of recipient purchased; and
                </li>
                <li>
                  disclosures for a business purpose, identifying the Personal
                  Information categories that each category of recipient
                  obtained.
                </li>
              </ul>
            </li>
          </ul>
          <p></p>

          <p class="p-section">Deletion Request Rights</p>

          <p>
            You have the right to request that Ma Labs delete any of your
            Personal Information that we collected from you and retained,
            subject to certain exceptions. Once we receive your request
            <strong>and verify your identity</strong> (see
            <a href="#exercising">Exercising Your Rights</a>), we will delete
            (and direct our service providers to delete) your Personal
            Information from our records, unless an exception applies.
          </p>

          <p>
            We may deny your deletion request if retaining the information is
            necessary for us or our service provider(s) for certain reasons as
            permitted and set forth in the CCPA. These reasons include (just to
            name a few):
          </p>

          <ol>
            <li>
              Completing the transaction for which the personal information was
              collected;
            </li>
            <li>Providing a good or service requested by you;</li>
            <li>
              Detecting security incidents, protecting against malicious,
              deceptive, fraudulent, or illegal activity, or prosecuting those
              responsible for such activities;
            </li>
            <li>
              Debugging products to identify and repair errors that impair
              existing intended functionality;
            </li>
            <li>Complying with a legal obligation;</li>
            <li>
              Making other internal and lawful uses of that information that are
              compatible with the context in which you provided it.
            </li>
          </ol>

          <p class="p-section" id="exercising">Exercising Your Rights</p>

          <p>
            Only you, or a person registered with the California Secretary of
            State that you authorize to act on your behalf, may make a
            verifiable Consumer request related to your Personal Information.
          </p>

          <p>
            An <u>authorized agent</u> is a natural person or a business entity
            registered with the Secretary of State that a Consumer has
            authorized to act on his or her behalf. When a Consumer uses an
            authorized agent to submit a request to know or a request to delete,
            Ma Labs may require that the Consumer provide the authorized agent
            written permission to do so and verify their own identity directly
            with Ma Labs, unless the Consumer has provided the authorized agent
            with a valid power of attorney. Ma Labs may deny a request from an
            agent that does not submit proof that they have been authorized by
            the Consumer to act on his or her behalf.
          </p>

          <p>
            To exercise the access, data portability, and deletion rights
            described above, please submit a verifiable Consumer request to us
            by either:
          </p>
          <ul>
            <li><a href="/">www.malabs.com</a></li>
            <li>
              <a href="mailto:privacynotice@malabs.com"
                >privacynotice@malabs.com</a
              >
            </li>
          </ul>
          <p></p>

          <p>
            You may (or your authorized agent) only make a verifiable Consumer
            request for access or data portability twice within a 12-month
            period. The verifiable Consumer request must:
          </p>
          <ul>
            <li>
              Provide sufficient information that allows us to reasonably verify
              you are the person about whom we collected Personal Information or
              an authorized representative.
            </li>
            <li>
              Describe your request with sufficient detail that allows us to
              properly understand, evaluate, and respond to it.
            </li>
          </ul>
          <p></p>

          <p>
            <strong
              >We cannot respond to your request or provide you with Personal
              Information if we cannot verify your identity or authority to make
              the request and confirm the Personal Information relates to
              you.</strong
            >
          </p>

          <p>
            Making a verifiable Consumer request does not require you to create
            an account with us. However, we do consider requests made through
            your password protected account as a reasonable step toward
            verification when the request relates to Personal Information
            associated with that specific account. Please note that the methods
            for verification are set forth in the CCPA, which also requires us
            to consider a number of factors, such as type, sensitivity and value
            of the Personal Information or risk of harm posed by unauthorized
            access or deletion, on a case-by-case basis.
          </p>

          <p>
            We will only use Personal Information provided in a verifiable
            Consumer request to verify the requestor's identity or authority to
            make the request.
          </p>

          <p class="p-section">Response Timing and Format</p>

          <p>
            We endeavor to respond to a verifiable Consumer request within
            forty-five (45) days of its receipt. If we require more time (up to
            90 days), we will inform you of the reason and extension period in
            writing.
          </p>

          <p>
            If you have an account with us, we will deliver our written response
            to that account. If you do not have an account with us, we will
            deliver our written response by mail or electronically, at your
            option.
          </p>

          <p>
            Any disclosures we provide will only cover the 12-month period
            preceding our receipt of the verifiable Consumer's request. If
            applicable, our response we provide will also explain the reasons we
            cannot comply with a request. For data portability requests, we will
            select a format to provide your Personal Information that is readily
            useable and should allow you to transmit the information from one
            entity to another entity without hindrance.
          </p>

          <p>
            We do not charge a fee to process or respond to your verifiable
            Consumer request unless it is excessive, repetitive, or manifestly
            unfounded. If we determine that the request warrants a fee, we will
            tell you why we made that decision and provide you with a cost
            estimate before completing your request.
          </p>

          <h3>Non-Discrimination</h3>

          <p>
            We will not discriminate against you in a manner prohibited by the
            CCPA because you exercise your CCPA rights. Please note that to use
            our Site or purchase Products, we do require the collection of your
            Personal Information, for example to sign up or in order to complete
            a paid transaction. While you may request to delete your Personal
            Information under CCPA, such deletions may affect Ma Labs' ability
            to offer the Site and its Products.
          </p>

          <h3>Questions About CCPA</h3>

          <p>
            If you have any questions regarding this CCPA Notice, please contact
            Ma Labs as follows:
          </p>

          <p>
            2075 N. Capitol Avenue, San Jose, CA 95132
            <span class="pull-right"
              >E-Mail:
              <a href="mailto:privacynotice@malabs.com"
                >privacynotice@malabs.com</a
              ></span
            >
          </p>

      </div>


  </div>
</template>

<script>
import CompanySubmenu from "../../components/nav/CompanySubmenu.vue";
import BreadcrumbPage from "../../components/BreadcrumbPage.vue";
import { getAPI } from '../../utils/axios-api'
import { ref, onMounted } from "vue";

export default {
  name: "PrivacyPolicy",
  components: { CompanySubmenu, BreadcrumbPage },
  setup() {
    const item = ref({});

    //breadcrumb
    const breadcrumb_links = ref([])
    breadcrumb_links.value.push({text: "Company", link: ""})
    breadcrumb_links.value.push({text: "Privacy Policy", link: "PrivacyPolicy"})

    onMounted(() => {
      getAPI.get("/content/pages/61").then((response) => {
        item.value = response.data.body[0].value;
      });
    });

    return { item, breadcrumb_links };

  },
};
</script>

<style scoped>
/* 
.submenu-content {
    float: left;
    width: calc(100% - 300px);
} */
.submenu-content h2{
    color: #0070c0;
}
p{
    margin: 0 0 10px;
}
/* .submenu-content ul li {
    width: 0%;
    height: 100px;
} */
.submenu-content ul {
    margin-left: 50px;
    list-style: disc;
    margin-bottom: 30px;
}
ul{
    margin: 0;
    padding: 0;
}

.submenu-content p.p-section {
    margin-bottom: 10px;
    color: #265197;
    font-style: italic;
}
.submenu-content h3 {
    margin-bottom: 20px;
}
.submenu-content a {
    text-decoration: underline;
}
a, a:active, a:focus, a:visited {
    color: #555;
}
.submenu-content table {
    margin-bottom: 20px;
}
pre code, table {
    background-color: transparent;
}
table {
    border-spacing: 0;
    border-collapse: collapse;
}
.submenu-content table tr:first-child td {
    color: #fff;
    background: #0070c0;
}
.submenu-content table td {
    width: 33%;
    padding: 20px;
    vertical-align: top;
    border: 1px
    solid #000;
}
</style>>
